import React, { Fragment, useState, useEffect } from 'react';
import {
    Typography, Card, Box, styled, useTheme, Grid, Avatar, Button, Divider, Dialog, DialogTitle, DialogContent, Chip
} from "@mui/material";
import PageTitleWrapper from '../../components/UI/PageTitleWrapper';
import PageHeaderAdvisory from './components/PageHeaderAdvisory';
import { InlineWidget } from 'react-calendly';
import TaraGriffinBio from './bios/TaraGriffinBio';


const CardBorderTop = styled(Card)(
    () => `
          border-top: transparent 5px solid;
    `
);

const advisory = [
    {
        id: 1,
        advisor: "Dr. Tara Griffin DMD, D.ABDSM, D.ASBA",
        imageLoc: "/images/advisors/griffin-headshot.JPG",
        link: "https://calendly.com/vivosca/tg-clinical-consultation",
        tags: ["Sleep Tests", "Vivos Guides", "Vivos PEx", "Vivos C.A.R.E Appliances", "Vivos Vida", "Vivos Vida Sleep", "Vivos Versa", "Airway Orthodontics", " Mandibular Advancement Devices", "TMD"],
        bioPreview: 'Dr. Tara Griffin has been a leading expert in sleep apnea and TMJ pain relief for over a decade.',
        bio: <TaraGriffinBio />,
    },
];

const useScript = (url) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [url]);
};

const useStyle = (url) => {
    useEffect(() => {
        const link = document.createElement('link');
        link.href = url;
        link.rel = 'stylesheet';
        document.head.appendChild(link);

        return () => {
            document.head.removeChild(link);
        };
    }, [url]);
};


function AdvisoryConsultationsPage() {
    const theme = useTheme();
    const [openBio, setOpenBio] = useState(false);
    const [selectedAdvisor, setSelectedAdvisor] = useState<React.ReactNode>(null);
    const [calendlyOpen, setCalendlyOpen] = useState(false);
    const [calendlyUrl, setCalendlyUrl] = useState("");

    const handleOpenAdvisorBio = (bioComponent: React.ReactNode) => {
        setSelectedAdvisor(bioComponent);
        setOpenBio(true);
    };


    const handleCloseBio = () => {
        setOpenBio(false);
    };

    const handleOpenCalendly = (link) => {
        setCalendlyUrl(link);
        setCalendlyOpen(true);
    };

    const handleCloseCalendly = () => {
        setCalendlyOpen(false);
    };

    return (
        <Fragment>
            <PageTitleWrapper>
                <PageHeaderAdvisory />
            </PageTitleWrapper>
            <Grid container spacing={2}>
                {advisory.map((doc) => (
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={doc.id}>
                        <CardBorderTop
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: 2,
                                marginBottom: 2,
                                borderTopColor: `${theme.colors.primary.main}`
                            }}>
                            <Avatar
                                alt={doc.advisor}
                                src={doc.imageLoc}
                                sx={{ width: 'auto', height: 200, marginBottom: 2 }}
                                variant="square"
                            />
                            <Grid>
                                <Typography gutterBottom variant="h4" align="center">
                                    {doc.advisor}
                                </Typography>
                            </Grid>
                            <Grid paddingY={2}>
                                <Typography variant="body2" color="textSecondary" align="center">
                                    {doc.bioPreview.substring(0, 100)}...
                                </Typography>
                            </Grid>
                            <Grid container justifyContent={'space-evenly'}>
                                <Button
                                    variant="contained"
                                    onClick={() => handleOpenCalendly(doc.link)}
                                >
                                    Schedule
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleOpenAdvisorBio(doc.bio)}
                                >
                                    View Advisor Bio
                                </Button>
                            </Grid>
                            <Box height={2} width="100%" bgcolor='lightgray' px={5} my={2} />
                            <Typography variant="subtitle2" align="center" sx={{ width: '100%' }}>
                                {/* <strong>Specialties:</strong> {doc.tags.join(', ')} */}
                                <Box
                                    sx={{
                                        mt: 1,
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'center',
                                        gap: 1.1,
                                    }}
                                >
                                    {doc.tags.map((tag, index) => (
                                        <Chip key={index} label={tag.trim()} variant="outlined" size="small" />
                                    ))}
                                </Box>
                            </Typography>
                        </CardBorderTop>
                    </Grid>
                ))}
                <Dialog open={calendlyOpen} onClose={handleCloseCalendly} fullWidth={true} maxWidth="lg">
                    <DialogTitle>Schedule an Appointment</DialogTitle>
                    <DialogContent>
                        <InlineWidget url={calendlyUrl} styles={{ height: '800px' }} />
                    </DialogContent>
                </Dialog>
                <Dialog open={openBio} onClose={handleCloseBio}>
                    <DialogTitle>Advisor Bio</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1" component="div">
                            {selectedAdvisor}
                        </Typography>
                    </DialogContent>
                </Dialog>
            </Grid>
        </Fragment>
    );
}

export default AdvisoryConsultationsPage;
